<template>
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" @submit.native.prevent>
      <!-- prettier-ignore -->
      <n-row-col2>
        <!-- companyId -->
        <n-field-selection v-model="model.companyId" field-name="companyId" selection-name="company.company" :error="error.companyId" label="company" :disabled="isEdit || disabled" />
        <!-- groupId -->
        <ns-group v-model="model.groupId" field-name="groupId" :company-id="model.companyId" :error="error.groupId" label="group" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- lpType -->
        <n-field-enum v-model="model.lpType" field-name="lpType" enum-name="LandingPageType" :error="error.lpType" label="lpType" :disabled="isEdit || disabled" />
        <!-- abTesting -->
        <n-field-boolean v-model="model.abTesting" label="abTesting" :error="error.abTesting" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-text v-model="model.iosStoreId" field-name="iosStoreId" label="iosStoreId" :error="error.iosStoreId" :disabled="disabled" />
        <n-field-text v-model="model.andStoreId" field-name="andStoreId" label="andStoreId" :error="error.andStoreId" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- variantPrefer -->
        <n-field-enum v-model="model.variantPrefer" field-name="variantPrefer" enum-name="LandingPageVariant" :error="error.variantPrefer" label="variantPrefer" :disabled="disabled" />
        <!-- variantARatio -->
        <n-field-percent v-model="model.variantARatio" format="0 %" field-name="variantARatio" :error="error.variantARatio" label="variantARatio" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- availableAfter -->
        <n-field-number v-model="model.availableAfter" field-name="availableAfter" :error="error.availableAfter" label="availableAfter" :disabled="disabled" suffix-text="ms" />
        <!-- availableDuration -->
        <n-field-number v-model="model.availableDuration" field-name="availableDuration" :error="error.availableDuration" label="availableDuration" :disabled="disabled" suffix-text="ms" />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { validate, edit, add } from '@/api/company/company-assignment';

export default NModal.extend({
  name: 'ModalCompanyAssignment',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },

  data() {
    return {
      variantARatioScale: 2,
    };
  },
});
</script>
