import { $fetch, $post, $put } from '@/services/axios';

export const CSV_URL = `company/assignments/csv`;

export function add(data) {
  return $put(`company/assignment`, data);
}

export function fetch(data) {
  return $fetch(`company/assignments`, data);
}

export function validate(data) {
  return $post(`company/assignment/validate`, data);
}

export function edit(data) {
  return $post(`company/assignment/${data.id}`, data);
}
