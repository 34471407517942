<template>
  <index-content>
    <template #options>
      <nb-download :url="CSV_URL" :params="condition" />
      <nb-add @on-click="openModal" />
    </template>
    <!-- prettier-ignore -->
    <search-condition>
      <ns-company v-model="condition.companyId" @on-change="onChangeCompany" />
      <n-field-selection v-model="condition.groupId" selection-name="company.group" :extra-group="condition.companyId" label="group" all-option @on-change="load" />
    </search-condition>
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th rowspan="2">{{ $t('label.id') }}</th>
            <th rowspan="2">{{ $t('label.group') }}</th>
            <th rowspan="2">{{ $t('label.type') }}</th>
            <th colspan="3">{{ $t('label.abTesting') }}</th>
            <th colspan="2">{{ $t('label.available') }}</th>
            <th v-table-action rowspan="2" class="action-edit">{{ $t('label.action') }}</th>
          </tr>
          <tr>
            <th>
              {{ $t('label.enabled') }}
            </th>
            <th>
              {{ $t('label.variantPrefer') }}
            </th>
            <th class="text-right">
              {{ $t('label.variantARatio') }}
            </th>
            <th>
              {{ $t('label.afterRegistration') }}
            </th>
            <th>
              {{ $t('label.duration') }}
            </th>
          </tr>
        </thead>
        <n-draggable v-model="records" :disabled="!sortable || condition.groupId === 0" @on-change="onDropped">
          <tr v-for="row in records" :key="row.id" :class="{ 'n-table-sortable': sortable && condition.groupId > 0 }">
            <td>{{ row.id }}</td>
            <td v-selection:[`company.group`]="row.groupId"></td>
            <td v-enums:LandingPageType="row.lpType"></td>
            <td><n-icon-boolean :value="row.html" /></td>
            <td v-enums:LandingPageVariant="row.variantPrefer"></td>
            <td class="text-right">
              <span
                  v-percentage="{ value: row.variantARatio, colorful: true, pattern: '0.00 %' }"
              />
            </td>
            <td>{{ row.availableAfter | duration }}</td>
            <td>{{ row.availableDuration | duration }}</td>
            <td class="n-button-box"><nb-modal @on-click="openModal(row)"></nb-modal></td>
          </tr>
        </n-draggable>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseCompanyView from './view';
import DndMixin from '@/mixins/dnd-mixin';
import ModalCompanyAssignment from './ModalCompanyAssignment';
import { CSV_URL, fetch } from '@/api/company/company-assignment';

export default BaseCompanyView.extend({
  name: 'CompanyAssignment',
  mixins: [DndMixin],

  data() {
    return {
      CSV_URL,
      preload: true,
      condition: {
        groupId: 0,
        companyId: 0,
      },
    };
  },

  methods: {
    onChangeCompany(v) {
      this.condition.groupId = 0;
      this.load();
    },

    doLoad() {
      return fetch({ ...this.condition });
    },

    parse(data) {
      this.records = data;
    },

    openModal(model = {}) {
      this.createModal(ModalCompanyAssignment, { model, on: this });
    },
  },
});
</script>
